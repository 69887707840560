import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Signup from './Signup'
import FourOhFour from '../FourOhFour';
import { SignupsContextProvider } from 'context/SignupsContext';

// https://stackoverflow.com/questions/47369023/react-router-v4-allow-only-certain-parameters-in-url

export default () => (
  <SignupsContextProvider>
    <Switch>
      <Route exact path="/signup/step/:step_num(1|2|3|4)" component={Signup} />
      <Redirect exact from="/signup" to="/signup/step/1"/>
      <Route component={FourOhFour} />
    </Switch>
  </SignupsContextProvider>
);
