import React from 'react';

export const LockIcon = () => (
  <svg width="16" height="22" viewBox="0 0 16 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 7.5H13V5.5C13 2.74 10.76 0.5 8 0.5C5.24 0.5 3 2.74 3 5.5V7.5H2C0.9 7.5 0 8.4 0 9.5V19.5C0 20.6 0.9 21.5 2 21.5H14C15.1 21.5 16 20.6 16 19.5V9.5C16 8.4 15.1 7.5 14 7.5ZM8 16.5C6.9 16.5 6 15.6 6 14.5C6 13.4 6.9 12.5 8 12.5C9.1 12.5 10 13.4 10 14.5C10 15.6 9.1 16.5 8 16.5ZM5 5.5V7.5H11V5.5C11 3.84 9.66 2.5 8 2.5C6.34 2.5 5 3.84 5 5.5Z"
      fill="white"
    />
  </svg>
);

export const SmallLockIcon = () => (
  <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.5 5.375H9.75V3.875C9.75 1.805 8.07 0.125 6 0.125C3.93 0.125 2.25 1.805 2.25 3.875V5.375H1.5C0.675 5.375 0 6.05 0 6.875V14.375C0 15.2 0.675 15.875 1.5 15.875H10.5C11.325 15.875 12 15.2 12 14.375V6.875C12 6.05 11.325 5.375 10.5 5.375ZM6 12.125C5.175 12.125 4.5 11.45 4.5 10.625C4.5 9.8 5.175 9.125 6 9.125C6.825 9.125 7.5 9.8 7.5 10.625C7.5 11.45 6.825 12.125 6 12.125ZM3.75 3.875V5.375H8.25V3.875C8.25 2.63 7.245 1.625 6 1.625C4.755 1.625 3.75 2.63 3.75 3.875Z"
      fill="white"
    />
  </svg>
);
