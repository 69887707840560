import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Tabs } from 'antd';

import AppLayout from 'components/AppLayout';
import MetaTags from 'components/MetaTags';
import CustomSteps from './components/CustomSteps';
import UserInfo from './components/UserInfo';
import Legalities from './components/Legalities';
import Confirmation from './components/Confirmation';
import Success from './components/Success';
import { useSignupsContextValue } from 'context/SignupsContext';
import { inspectletTrackPage, inspectletIdentify, inspectletUpdateUserStep } from 'config/inspectlet';

const { TabPane } = Tabs;

const SIGNUP_STEPS = [{
  title: 'USER_INFO',
  component: <UserInfo />,
}, {
  title: 'LEGALITIES',
  component: <Legalities />,
}, {
  title: 'CONFIRMATION',
  component: <Confirmation />,
}, {
  title: 'SUCCESS',
  component: <Success />,
}];

const StyledTabs = styled(Tabs)`
  .ant-tabs-nav {
    display: none;
  }
`;

const Signup: React.FC<RouteComponentProps<{ step_num: string }>> = ({ match, history, location }) => {
  const { airtableUser } = useSignupsContextValue();
  const [currentStep, setCurrentStep] = useState(0);

  useEffect(() => {
    if ([2].includes(+match.params.step_num) && !airtableUser) {
      history.replace('/signup/step/1');
    } else {
      setCurrentStep(+match.params.step_num - 1);
    }
    if (airtableUser) inspectletUpdateUserStep(airtableUser, +match.params.step_num);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params.step_num, airtableUser]);

  useEffect(() => {
    if (window) inspectletTrackPage(window.location.href);
    if (airtableUser) inspectletIdentify(airtableUser)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  return (
    <AppLayout>
      <MetaTags metaTitle="New player signup" />
      <div>
        <CustomSteps current={currentStep} stepsCount={SIGNUP_STEPS.length} />
        <StyledTabs activeKey={(currentStep + 1).toString()}>
          {SIGNUP_STEPS.map(({ title, component }, idx) => (
            <TabPane tab={title} key={(idx + 1)}>
              <div className="d-flex justify-content-center">
                {component}
              </div>
            </TabPane>
          ))}
        </StyledTabs>
      </div>
    </AppLayout>
  )
}

export default withRouter(Signup);
