import React from 'react';
import cx from 'classnames';
import styled from 'styled-components';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Typography, Grid, message } from 'antd';

import MetaTags from 'components/MetaTags';
import UserForm from './components/UserForm';
import { IUserFormFields } from './components/UserForm/UserForm';
import { useSignupsContextValue } from 'context/SignupsContext';
import api, { IUserResponse } from 'config/api';

const { Text, Title } = Typography;
const { useBreakpoint } = Grid;

const StyledUserInfoWrapper = styled.div`
  .sub-heading {
    margin-bottom: 32px;
  }
  .custom-container {
    padding: 32px 112px;
  }
  .bs-customer-container {
    padding: 0 38px;
  }
`;

const UserInfo: React.FC<RouteComponentProps> = ({ history }) => {
  const screens = useBreakpoint();
  const { setCurrentUser } = useSignupsContextValue();

  const onSubmit = async (
    values: IUserFormFields,
    setSubmitting: (isSubmitting: boolean) => void,
  ) => {
    const { data } = await api.post<IUserResponse>('/user', Object.assign({}, values));
    if (data.ok) {
      setSubmitting(false);
      setCurrentUser(data.user);
      history.push('/signup/step/2');
    } if (!data.ok && data.error) {
      setSubmitting(false);
      message.error(data.error.message);
    }
  }

  return (
    <StyledUserInfoWrapper className="w-100">
      <MetaTags metaTitle="New player signup | Step 1" />
      <Text
        className={cx({
          "sub-heading d-block text-center": true,
          "fs-20": screens.xs,
        })}
      >
        Get started with UBOmoney
      </Text>
      <div
        className={cx({
          "mx-auto": true,
          "custom-container bg-secondary": !screens.xs,
          "bs-customer-container": screens.xs,
        })}
      >
        <Title level={3} className="mb-4 text-center">Tell us about yourself</Title>
        <UserForm onSubmit={onSubmit} />
      </div>
    </StyledUserInfoWrapper>
  );
}

export default withRouter(UserInfo);
