import * as Yup from 'yup';
import { UploadFile } from 'antd/lib/upload/interface';

const MAX_FILE_SIZE = 10; // mb
export const SUPPORTED_FILE_EXTENSIONS = ['JPEG', 'JPG', 'PNG', 'PDF', 'TIF'];

export const legalitiesFormSchema = Yup.object().shape({
  fileToUpload: Yup.object()
    .required('You must upload a valid government ID')
    .test('File type checker', 'You are uploading an unsupported file type.', (fileToUpload: UploadFile) => {
      if (fileToUpload) {
        const fileExtension = fileToUpload.name.split('.').pop()?.toUpperCase();
        return fileExtension ? SUPPORTED_FILE_EXTENSIONS.includes(fileExtension) : false;
      }
      return false;
    })
    .test('File size checker', 'File must not exceed 10mb.', (fileToUpload: UploadFile) => {
      return fileToUpload ? (fileToUpload.size / 1024 / 1024) <= MAX_FILE_SIZE : false;
    }),
  termsAndConditions: Yup.boolean()
    .oneOf([true], 'Please read terms and conditions, field must be accepted.')
});
