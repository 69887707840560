import React, { createContext, useContext, useState, useEffect, PropsWithChildren } from 'react';

export type TUser = {
  avatar: string | null;
  email: string;
  firstname: string;
  lastname: string;
  id: string;
}

interface ISignupsContext {
  airtableUser: TUser | null,
  setCurrentUser: (currentUser: TUser | null) => void,
}

const SignupsContext = createContext<ISignupsContext>({
  airtableUser: null,
  setCurrentUser: _ => {},
});

const SignupsContextProvider: React.FC<PropsWithChildren<any>> = ({ children }) => {
  let derivedUser = localStorage.getItem('currentUser');

  const [airtableUser, setAirtableUser] = useState<TUser | null>(derivedUser ? JSON.parse(derivedUser) : null);

  useEffect(() => {
    setCurrentUser(derivedUser ? JSON.parse(derivedUser) : null);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setCurrentUser = (user: TUser | null) => {
    if (user) localStorage.setItem('currentUser', JSON.stringify(user));
    else localStorage.removeItem('currentUser');
    setAirtableUser(user);
  }

  return (
    <SignupsContext.Provider
      value={{
        airtableUser, setCurrentUser,
      }}
    >
      {children}
    </SignupsContext.Provider>
  );
}

const useSignupsContextValue = () => useContext(SignupsContext);
export { SignupsContextProvider, useSignupsContextValue };
export default SignupsContext;
