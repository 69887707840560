import React, { useEffect } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import cx from 'classnames';
import styled from 'styled-components';
import { Typography, Grid } from 'antd';

import MetaTags from 'components/MetaTags';
import { useSignupsContextValue } from 'context/SignupsContext';

const { Text, Title, Paragraph } = Typography;
const { useBreakpoint } = Grid;

const StyledSuccessWrapper = styled.div`
  .sub-heading {
    margin-bottom: 32px;
  }
  .custom-container {
    padding: 32px 48px;
  }
`;

const Success: React.FC<RouteComponentProps> = () => {
  const screens = useBreakpoint();
  const { setCurrentUser } = useSignupsContextValue();

  useEffect(() => {
    // if (!airtableUser?.email_verification) history.replace('/signup/step/3');
    setCurrentUser(null);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledSuccessWrapper>
      <MetaTags metaTitle="New player signup | Success" />
      <Text
        className={cx({
          "sub-heading d-block text-center": true,
          "fs-20": screens.xs,
        })}
      >
        Welcome to UBOmoney!
      </Text>
      <div
        className={cx({
          "mx-auto": true,
          "custom-container bg-secondary": !screens.xs,
          "px-3": screens.xs,
          "text-center": screens.xs,
        })}
      >
        <Title level={3} className="mb-4">Registration successfully submitted</Title>
        <Paragraph>
          Thank you for applying for a UBOmoney account.
        </Paragraph>
        <Paragraph>
          Your application is under review. We will be in touch via email to inform you about its outcome.
        </Paragraph>
      </div>
    </StyledSuccessWrapper>
  );
}

export default withRouter(Success);
