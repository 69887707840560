import { TUser } from 'context/SignupsContext';
import { isDev } from 'config/env';

const inspectlet = isDev ? null : (window as any).__insp;

export const inspectletTrackPage = (url: string) => {
  if (inspectlet) {
    inspectlet.push(['pageUrl', url]);
  }
}

export const inspectletIdentify = (user: TUser) => {
  if (inspectlet) {
    inspectlet.push(['identify', user.email]);
  }
}

export const inspectletUpdateUserStep = (user: TUser, step: number) => {
  if (inspectlet) {
    inspectlet.push([
      'tagSession', Object.assign(user, {
        step,
      }),
    ]);
  }
}
