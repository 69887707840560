import React from 'react';
import { withFormik, FormikProps } from 'formik';
import cx from 'classnames';
import styled from 'styled-components';
import { Typography, Upload, Button, Row, Col, Grid, Checkbox, Form } from 'antd';
import { UploadFile } from 'antd/lib/upload/interface';
import { UploadOutlined } from '@ant-design/icons';

import FileToUpload from './components/FileToUpload';
import TermsAndCondition from './components/TermsAndConditions';
import { legalitiesFormSchema, SUPPORTED_FILE_EXTENSIONS } from './validation';
import { LockIcon, SmallLockIcon } from 'utils/iconUtils';

const { useBreakpoint } = Grid;
const { Paragraph, Text } = Typography;

export interface ILegalitiesFormFields {
  termsAndConditions: boolean;
  fileToUpload: UploadFile | undefined;
}

interface IExternalProps {
  onSubmit: (
    file: UploadFile,
    setSubmitting: (isSubmitting: boolean) => void,
  ) => void,
}

const StyledButton = styled(Button)`
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(255, 255, 255, 0.5);
`;

const StyledCheckboxWrapper = styled.div`
  padding: 24px 0;
  .ant-checkbox-wrapper {
    span {
      padding-right: 4px;
      color: #FFF;
      &.ant-checkbox {
        padding-right: 0;
        .ant-checkbox-inner {
          width: 18px;
          height: 18px;
          background-color: #323234;
          border: 2px solid #d9d9d9;
        }
        &.ant-checkbox-checked {
          .ant-checkbox-inner {
            background-color: #1B1D21;
            border-color: #1B1D21 !important;
            &::after {
              border-color: #FFF;
            }
          }
        }
      }
    }
  }
  .ant-btn {
    color: #F3AF2C;
    span {
      text-decoration-line: underline;
    }
  }
`;

const LegalitiesForm: React.FC<FormikProps<ILegalitiesFormFields>> = ({
  values, isSubmitting, errors, touched, handleSubmit,
  setFieldValue, setFieldTouched, handleChange,
}) => {

  const screens = useBreakpoint();
  const DerivedLockIcon = screens.xs ? SmallLockIcon : LockIcon;
  const { fileToUpload, termsAndConditions } = values;

  return (
    <Form onSubmitCapture={handleSubmit}>
      {!fileToUpload && (
        <Paragraph
          className={cx({
            "d-flex mb-3": true,
            "fs-12": screens.xs,
          })}
        >
          <div className="d-flex align-items-center" style={{ marginRight: screens.xs ? 7 : 12 }}>
            <DerivedLockIcon />
          </div>
          All personal information shared is secured via SSL.
        </Paragraph>
      )}
      <Row
        className="mb-0 mb-sm-2"
        {...(screens.xs && {
          gutter: [16, 16],
        })}
      >
        <Col className="d-flex align-items-center" xs={24} sm={24} md={12} lg={12}>
          <Text>Valid government ID</Text>
        </Col>
        <Col className={cx({ "text-right": !screens.xs })} xs={24} sm={24} md={12} lg={12}>
          <div>
            {fileToUpload ? (
              <FileToUpload
                filename={fileToUpload.name}
                isFileValid={!errors.fileToUpload}
                isSubmitting={isSubmitting}
                setFileToUpload={() => {
                  setFieldValue('fileToUpload', undefined);
                }}
              />
            ) : (
              <Upload
                action={undefined}
                showUploadList={false}
                accept={SUPPORTED_FILE_EXTENSIONS.map(x => `.${x}`.toLowerCase()).join(',')}
                name="fileToUpload"
                onChange={info => {
                  setFieldValue('fileToUpload', info.file);
                  if (!touched.fileToUpload) setFieldTouched('fileToUpload');
                }}
                customRequest={() => {}}
              >
                <StyledButton className="text-white bg-secondary" size="large">
                  <UploadOutlined /> Upload
                </StyledButton>
              </Upload>
            )}
          </div>
        </Col>
      </Row>
      {(touched.fileToUpload && errors.fileToUpload) && (
        <div className={cx({ "text-right": !screens.xs })}><Text className="error-text">{errors.fileToUpload}</Text></div>
      )}
      <StyledCheckboxWrapper className="text-center">
        <Checkbox
          className="text-white"
          disabled={isSubmitting}
          name="termsAndConditions"
          checked={termsAndConditions}
          onChange={e => {
            handleChange(e);
            if (!touched.termsAndConditions) setFieldTouched('termsAndConditions');
          }}
        >
          I have read and accept the
        </Checkbox>
        <TermsAndCondition />
        {(touched.termsAndConditions && errors.termsAndConditions && !termsAndConditions) && (
          <div><Text className="error-text">{errors.termsAndConditions}</Text></div>
        )}
      </StyledCheckboxWrapper>
      <Button loading={isSubmitting} htmlType="submit" type="primary" className="text-white" block size="large">Create account</Button>
    </Form>
  );
}

export default withFormik<IExternalProps, ILegalitiesFormFields>({
  validationSchema: legalitiesFormSchema,
  mapPropsToValues: () => ({
    fileToUpload: undefined,
    termsAndConditions: false,
  }),
  handleSubmit: (values, formikBag) => {
    if (values.fileToUpload) {
      formikBag.props.onSubmit(values.fileToUpload, formikBag.setSubmitting);
    }
  },
  displayName: 'LegalitiesForm',
})(LegalitiesForm);
