import React from 'react';
import cx from 'classnames';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';
import { Typography, message, Grid } from 'antd';
import { UploadFile } from 'antd/lib/upload/interface';

import MetaTags from 'components/MetaTags';
import LegalitiesForm from './components/LegalitiesForm';
import { useSignupsContextValue } from 'context/SignupsContext';
import api, { IUserResponse } from 'config/api';

const { Text, Title, Paragraph } = Typography;
const { useBreakpoint } = Grid;

const StyledLegalitiesWrapper = styled.div`
  .sub-heading {
    margin-bottom: 32px;
  }
  .custom-container {
    padding: 32px 48px;
  }
`;

const Legalities: React.FC<RouteComponentProps> = ({ history }) => {
  const screens = useBreakpoint();
  const { airtableUser, setCurrentUser } = useSignupsContextValue();

  const onSubmit = async (
    file: UploadFile,
    setSubmitting: (isSubmitting: boolean) => void,
  ) => {
    try {
      const formData = new FormData();
      formData.append('avatar', file.originFileObj as Blob);
      const { data } = await api.patch<IUserResponse>(`/user/${airtableUser?.id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
      });
      if (data.ok) {
        setSubmitting(false);
        setCurrentUser(data.user);
        history.push('/signup/step/3');
      } else if (!data.ok && data.error) {
        setSubmitting(false);
        message.error(data.error.message);
      }
    } catch (e) {
      alert(JSON.stringify(e));
    }
  }

  return (
    <StyledLegalitiesWrapper className="w-100">
      <MetaTags metaTitle="New player signup | Step 2" />
      <Text
        className={cx({
          "sub-heading d-block text-center": true,
          "fs-20": screens.xs,
        })}
      >
        Almost there!
      </Text>
      <div
        className={cx({
          "mx-auto": true,
          "custom-container bg-secondary": !screens.xs,
          "px-3": screens.xs,
        })}
      >
        <Title level={3} className="mb-4 text-center">Making sure it’s you</Title>
        <Paragraph className="mb-3">
          As part of our verification process, we require new players to submit <Text strong>one valid government ID</Text>. This helps us confirm that you are of legal age.
        </Paragraph>
        <Paragraph className="mb-3">
          The government ID you submit must include the following: <Text strong>Full Name, Address, Date of Birth, and Photo</Text>. An example of this would be a <Text strong underline>Drivers License</Text> or a <Text strong underline>Personal ID card</Text>
        </Paragraph>
        <Paragraph className="mb-3">
          Please note that ID’s such as a Passport are not accepted as they do not have an address.
        </Paragraph>
        <Paragraph className="mb-4">
          Please note that government employees are not allowed to register as players on UBOmoney.
        </Paragraph>
        <LegalitiesForm onSubmit={onSubmit} />
      </div>
    </StyledLegalitiesWrapper>
  );
}

export default withRouter(Legalities);
