import axios from 'axios';

import { TUser } from 'context/SignupsContext';

export default axios.create({
  baseURL: 'https://api.basketislife.com'
});

export type TError = {
  message: string;
}

export interface IUserResponse {
  ok: boolean;
  user: TUser;
  error?: TError;
}
