import React from 'react';
import styled from 'styled-components';
import { Steps } from 'antd';

const { Step } = Steps;

const StyledStepsWrapper = styled.div`
  .ant-steps {
    max-width: 120px;
    .ant-steps-item {
      .ant-steps-item-container {
        .ant-steps-item-tail {
          top: 4px;
          margin: 0;
          &::after {
            width: 24px;
            height: 2px;
            background: #323234;
          }
        }
        .ant-steps-item-icon {
          margin: 0;
          top: 0;
          width: 12px;
          height: 12px;
          line-height: 12px;
          .ant-steps-icon {
            color: #323234;
            .ant-steps-icon-dot {
              background: #323234;
              &::after {
                display: none;
              }
            }
          }
        }
        .ant-steps-item-content {
          display: none;
        }
      }
      &.ant-steps-item-finish {
        .ant-steps-item-tail {
          &::after {
            background: #F3AF2C;
          }
        }
      }
      &.ant-steps-item-finish, &.ant-steps-item-active {
        .ant-steps-item-icon {
          .ant-steps-icon {
            color: #F3AF2C;
            .ant-steps-icon-dot {
              background: #F3AF2C;
            }
          }
        }
      }
    }
  }
`;

const CustomSteps: React.FC<{ stepsCount: number; current: number }> = ({ stepsCount, current }) => {
  const stepsArray = Array.from(Array(stepsCount), (_, i) => i);
  return (
    <StyledStepsWrapper className="mb-4">
      <Steps className="mx-auto" current={current} progressDot>
        {stepsArray.map((_, idx) => (
          <Step key={idx} />
        ))}
      </Steps>
    </StyledStepsWrapper>
  );
};

export default CustomSteps;
