import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, Modal, Typography } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

const { Title, Paragraph } = Typography;

const StyledModal = styled(Modal)`
  .ant-modal-header {
    background: #323234;
    border-radius: 0;
    padding: 32px 40px;
    border: 0;
  }
  .ant-modal-body {
    background: #323234;
    color: #FFF;
    padding: 0 40px 32px;
    max-height: 576px;
    overflow: auto;

    /* total width */
    &::-webkit-scrollbar {
      width: 6px !important;
      margin-right: 8px;
    }

    /* scrollbar itself */
    &::-webkit-scrollbar-thumb {
      background-color: #babac0 !important;
      border-radius: 12px !important;
      border: none !important;
    }

    /* set button(top and bottom of the scrollbar) */
    &::-webkit-scrollbar-button {
      display: none !important;
    }
  }
  .ant-modal-close {
    color: #BFBFBF;  
  }
`;

const TermsAndConditions = () => {
  const [modalState, setModalState] = useState(false);
  return (
    <>
      <Button onClick={() => setModalState(true)} className="p-0" type="text">terms and conditions.</Button>
      <StyledModal
        visible={modalState}
        closeIcon={<CloseOutlined />}
        onCancel={() => setModalState(false)}
        footer={false}
        maskClosable={false}
        title={<Title className="m-0" level={3}>Terms and conditions</Title>}
      >
        <Paragraph>
          These rules form an agreement between ourselves and our members. Registering with us is taken as your acceptance of these rules.
        </Paragraph>
        <Paragraph>
          Members are fully responsible for ensuring contact and payment details are accurate at all times.
        </Paragraph>
        <Paragraph>
          We accept bets from those aged 21 years and over only, and may ask for proof of age at any point. Members may have their account suspended until such proof is provided.
        </Paragraph>
        <Paragraph>
          Members must keep their sensitive details confidential at all times. If you believe your details are no longer confidential, you must inform us immediately, as any account activity is your responsibility.
        </Paragraph>
        <Paragraph>
          Placed and confirmed bets may not be changed or cancelled.
        </Paragraph>
        <Paragraph>
          Members are responsible for ensuring placing bets is permitted in their country of residence.
        </Paragraph>
        <Paragraph>
          Members can only place bets on our website. Attempting to place a bet in any other way will not be accepted.
        </Paragraph>
        <Paragraph>
          Bets or any part of a bet may be cancelled and/or refused at any point before the event in question without notice.
        </Paragraph>
        <Paragraph>
          We will take all action necessary to prevent any form of betting fraud. Any member or group of members found to be placing bets in such a way as to defraud us will have their accounts suspended and bets voided, whether bets are settled or not.
        </Paragraph>
        <Paragraph>
          We may void bets that have been accepted when there were insufficient funds in the account to cover the bet, Payments that are subsequently cancelled by a payment processor will result in cancelled bets.
        </Paragraph>
        <Paragraph>
          Any funds credited to a member account in error should be reported to us immediately. Any winnings derived from such funds will be voided and reclaimed by us.
        </Paragraph>
        <Paragraph>
          These Terms and Conditions are presented in English. In the case of any ambiguity of these Terms and Conditions in other languages, the English version shall prevail.
        </Paragraph>
        <Paragraph>
          Multiple accounts are not permitted. We reserve the right to void all bets and close any accounts found to be duplicated.
        </Paragraph>
        <Paragraph>
          Bets may be placed up to the advertised start time. Any bets placed and accepted after the event start time shall be deemed as a non-runner.
        </Paragraph>
        <Paragraph>
          Bets on different selections within the same event where the outcome of one affects or is affected by another will not be accepted and will be voided in the case of accidental acceptance.
        </Paragraph>
        <Paragraph>
          'If Bets' will be settled according to the order in which they appear on the betting slip until assigned monies have been used. In the case of these funds being insufficient to complete or continue the bet, the selection in question will be settled according to the amount available.
        </Paragraph>
        <Paragraph>
          We will not be held responsible for any damages/losses resulting from use of our website or related content including operation or transmission delays or interruptions, loss/corruption of data, line/communication failure, misuse of website or associated content, or any errors/omissions on the website or associated content.
        </Paragraph>
        <Paragraph>
          We may offer lower odds on some bet types than is offered on singles.
        </Paragraph>
        <Paragraph>
          Members are advised to maintain records of all transactions, game rules, cancellations rules and payment services for future reference.
        </Paragraph>
        <Paragraph>
          Bets are processed and deemed active once they have been placed and acceptance confirmed.
        </Paragraph>
        <Paragraph>
          Every effort is made to ensure all information displayed on the website is accurate, but should be used only as a guide. We cannot be held responsible for any errors in event details. Members are advised to consult our Betting Rules for details of bet settlements in such circumstances.
        </Paragraph>
        <Paragraph>
          Members can place bets as soon as funds have been successfully added to their account.
        </Paragraph>
        <Paragraph>
          Members accept that we may use first name, username and country of residence of significant winners for advertising purposes. In all other cases, members' details will remain confidential.
        </Paragraph>
        <Paragraph>
          Bets on events that are subsequently cancelled will be voided and monies refunded.
        </Paragraph>
        <Paragraph>
          Member offers are limited to one per person, family, address, email, phone number, payment account number and computer, We reserve the right to withdraw offers from any member or group of members.
        </Paragraph>
        <Paragraph>
          Member account is deemed as unique regarding your name and personal details, and may only be used by you. It shall not be duplicated under any circumstances.
        </Paragraph>
        <Paragraph>
          In the case of duplicated accounts, we may close such an account and:
        </Paragraph>
        <ul className="mb-3">
          <li>Refund all deposits less voided winnings</li>
          <li>Reclaim any other funds from any other duplicate accounts</li>
        </ul>
        <Paragraph>
          In the case of bets placed on incorrect prices, bets will still stand but will be settled at the revised price.
        </Paragraph>
        <Paragraph>
          These terms and conditions will be updated from time to time and it is the members' responsibility to check regularly for such updates.
        </Paragraph>
        <Paragraph>
          Monies deposited to us are protected in the event of our insolvency with such monies being held by our bank in designated accounts.
        </Paragraph>
        <Paragraph>
          UBOmoney may contact me via email about offers, information or promotions which may be of interest to me.
        </Paragraph>
      </StyledModal>
    </>
  );
}

export default TermsAndConditions;
