import React from 'react';
import { withFormik, FormikProps } from 'formik';
import styled from 'styled-components';
import { Form, Input, Button, Typography } from 'antd';

import { userFormSchema } from './validation';

const { Text } = Typography;

export interface IUserFormFields {
  firstname: string;
  lastname: string;
  email: string;
}

interface IExternalProps {
  onSubmit: (
    values: IUserFormFields,
    setSubmitting: (isSubmitting: boolean) => void,
  ) => void,
}

const StyledForm = styled(Form)`
  &.ant-form {
    width: 100%;
    .ant-form-item {
      .ant-form-item-label {
        padding-bottom: 4px;
        label {
          color: #FFF;
        }
      }
      .ant-input {
        border: 1px solid #474747;
        border-radius: 0;
        color: #FFF;
        &::placeholder {
          color: #474747;
        }
      }  
      &.ant-form-item-has-error {
        margin-bottom: 5px;
        .ant-form-item-control {
          .ant-input {
            border: 1px solid #FF4E4E;
          }
          .ant-form-item-explain {
            font-size: 11px;
            line-height: 15px;
            margin-top: 4px;
            div {
              color: #FF4E4E !important;
            }
          }
        }
      }
    }
  }
`;

const UserForm: React.FC<FormikProps<IUserFormFields>> = ({
  values, isSubmitting, handleChange, handleBlur, handleSubmit, errors, touched,
}) => {
  return (
    <StyledForm colon={false} layout="vertical" onSubmitCapture={handleSubmit}>
      <Form.Item
        label="First name"
        {...((touched.firstname && errors.firstname) && {
          validateStatus: "error",
          help: errors.firstname,
        })}
      >
        <Input
          name="firstname"
          className="bg-primary"
          placeholder="Robin"
          type="text"
          size="large"
          value={values.firstname}
          onChange={handleChange}
          onBlur={handleBlur}
          disabled={isSubmitting}
        />
      </Form.Item>
      <Form.Item
        label="Last name"
        {...((touched.lastname && errors.lastname) && {
          validateStatus: "error",
          help: errors.lastname,
        })}
      >
        <Input
          name="lastname"
          className="bg-primary"
          placeholder="Charles"
          type="text"
          size="large"
          value={values.lastname}
          onChange={handleChange}
          onBlur={handleBlur}
          disabled={isSubmitting}
        />
      </Form.Item>
      <Form.Item
        label="Email"
        {...((touched.email && errors.email) && {
          validateStatus: "error",
          help: errors.email,
        })}
      >
        <Input
          name="email"
          className="bg-primary"
          placeholder="robincharles@email.com"
          type="email"
          size="large"
          value={values.email}
          onChange={handleChange}
          onBlur={handleBlur}
          disabled={isSubmitting}
        />
      </Form.Item>
      <Button loading={isSubmitting} htmlType="submit" type="primary" className="text-white mb-2" block size="large">Next</Button>
      <div className="text-center">
        <Text>By clicking "next" you agree to receive emails in the future from UBOmoney</Text>
      </div>
    </StyledForm>
  )
}

export default withFormik<IExternalProps, IUserFormFields>({
  validationSchema: userFormSchema,
  handleSubmit: (values, formikBag) => {
    formikBag.props.onSubmit(values, formikBag.setSubmitting);
  },
  displayName: 'UserForm',
})(UserForm);
