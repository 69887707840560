import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Helmet } from "react-helmet";

const LOGO = '/assets/images/logo.png';

interface IMetaTags extends RouteComponentProps {
  metaTitle: string,
  metaDescription?: string,
  metaImg?: string,
}

const MetaTags: React.FC<IMetaTags> = ({
  match, metaTitle,
  metaDescription = 'The most diverse and fun gaming site to play at',
  metaImg = LOGO,
}) => {
  return (
    <Helmet>
      <title>{metaTitle} · {process.env.REACT_APP_NAME}</title>
      <meta name="title" content={`${metaTitle} · ${process.env.REACT_APP_NAME}`} />
      <meta name="description" content={metaDescription} />
      <meta name="apple-mobile-web-app-title" content={process.env.REACT_APP_NAME} />

      <meta property="og:type" content="website" />
      <meta property="og:url" content={match.url} />
      <meta property="og:title" content={`${metaTitle} · ${process.env.REACT_APP_NAME}`} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:image" content={metaImg} />

      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={match.url} />
      <meta property="twitter:title" content={`${metaTitle} · ${process.env.REACT_APP_NAME}`} />
      <meta property="twitter:description" content={metaDescription} />
      <meta property="twitter:image" content={metaImg} />
    </Helmet>
  );
};

export default withRouter(MetaTags);
