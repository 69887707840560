import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';
import { Typography, message, Spin, Grid, Button } from 'antd';
import queryString from 'query-string';

import MetaTags from 'components/MetaTags';
import { LoadingIcon } from 'components/Spinner/Spinner';
import { useSignupsContextValue } from 'context/SignupsContext';
import api, { IUserResponse } from 'config/api'

const { Text, Title, Paragraph } = Typography;
const { useBreakpoint } = Grid;

const StyledConfirmationWrapper = styled.div`
  .sub-heading {
    margin-bottom: 32px;
  }
  .custom-container {
    padding: 32px 48px;
  }
  .ant-btn > span {
    color: #F3AF2C !important;
  }
`;

const StyledText = styled(Text)`
  color: #F3AF2C !important;
`;

const Confirmation: React.FC<RouteComponentProps> = ({ location, history }) => {
  const screens = useBreakpoint();
  const queryParams = queryString.parse(location.search);
  const [resendingState, setResendingState] = useState(false);
  const [loadingState, setLoadingState] = useState(Boolean(queryParams.code) || false);
  const { airtableUser } = useSignupsContextValue();

  useEffect(() => {
    // if (!airtableUser) return setCurrentUser(null);
    if (airtableUser && !airtableUser.avatar) return history.replace('/signup/step/2');
    if (queryParams.code) {
      emailVerified();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const emailVerified = async () => {
    setLoadingState(true);
    const { data } = await api.post<IUserResponse>(`/verify?code=${queryParams.code}`);
    if (data.ok) {
      setLoadingState(false);
      history.push('/signup/step/4');
    } else if (!data.ok && data.error) {
      setLoadingState(false);
      message.error(data.error.message);
    }
  }

  const resendEmail = async () => {
    setResendingState(true);
    const { data } = await api.post<IUserResponse>(`/resend?email=${airtableUser?.email}`);
    if (data.ok) {
      message.success('Confirmation email has been resent.');
    } else if (!data.ok && data.error) {
      message.error(data.error.message);
    }
    setResendingState(false);
  }

  return (
    <StyledConfirmationWrapper>
      <MetaTags metaTitle="New player signup | Step 3" />
      <Text
        className={cx({
          "sub-heading d-block text-center": true,
          "fs-20": screens.xs,
        })}
      >
        Welcome to UBOmoney!
      </Text>
      <Spin spinning={loadingState} indicator={LoadingIcon}>
        <div
          className={cx({
            "mx-auto": true,
            "custom-container bg-secondary": !screens.xs,
            "px-3": screens.xs,
          })}
        >
          <Title level={3} className="mb-4 text-center">Please confirm your email</Title>
          <Paragraph>
            We’ll be sending a confirmation email to <StyledText strong>{airtableUser?.email}</StyledText>. Please make sure that you have included a valid email address, as this is how you will receive your login details.
          </Paragraph>
        </div>
        {airtableUser && (
          <Button loading={resendingState} onClick={resendEmail} className="mt-3 mx-auto d-block" type="text">Didn't receive any email? Resend</Button>
        )}
      </Spin>
    </StyledConfirmationWrapper>
  );
}

export default withRouter(Confirmation);
