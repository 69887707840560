import React from 'react';
import cx from 'classnames';
import styled from 'styled-components';
import { Tooltip, Button, Typography } from 'antd';
import { FileUnknownOutlined, LinkOutlined, CloseOutlined, CheckCircleOutlined } from '@ant-design/icons';

const { Text } = Typography;

interface IFileToUpload {
  isFileValid: boolean;
  filename: string;
  setFileToUpload: () => void;
  isSubmitting: boolean;
}

const StyledFileNameWrapper = styled.div`
  .ant-typography, .file-type {
    color: rgba(255, 255, 255, 0.8) !important;
  }
  &:not(.uploading) {
    &:hover {
      .valid-indicator {
        display: none;
      }
      .ant-btn {
        display: inline-block !important;
      }
    }
  }
`;

const StyledCloseButton = styled(Button)`
  padding: 0;
  width: auto;
  height: auto;
  line-height: 0;
  border: 0;
  .anticon {
    color: #FF4E4E !important;
    font-size: 14px;
  }
`;

const FileToUpload: React.FC<IFileToUpload>  = ({ isSubmitting, isFileValid, filename, setFileToUpload }) => {
  return (
    <StyledFileNameWrapper className={cx({ "uploading": isSubmitting })}>
      {isFileValid ? <LinkOutlined className="file-type" /> : (
        <Tooltip title="Unsupported file type, please upload a different file. (Supported file types .jpeg, .jpg, .png, .pdf, .tif)">
          <FileUnknownOutlined className="file-type" />
        </Tooltip>
      )}
      <Text className="mx-2">{filename}</Text>
      {isFileValid && (
        <CheckCircleOutlined className="valid-indicator" style={{ fontSize: 16, color: '#F3AF2C' }} />
      )}
      <StyledCloseButton
        title="Remove"
        className={cx({
          "bg-transparent": true,
          "d-none": isFileValid,
        })}
        icon={<CloseOutlined />}
        onClick={setFileToUpload}
      />
    </StyledFileNameWrapper>
  );
}

export default FileToUpload;
